import "bootstrap/dist/css/bootstrap.min.css";
import VideoPlayer from "../components/VideoPlayer";
import { useTranslation } from 'react-i18next';

export default function FreeTrialPassPage() {
    const language = localStorage.getItem('language') || 'en';

    const { t } = useTranslation();

    const getTextAlign = (lang) => lang === 'ar' ? 'right' : 'left';
    const getDirection = (lang) => lang === 'ar' ? 'rtl' : 'ltr';

    return (
        <div className="d-flex flex-column justify-content-center align-items-center w-100">
            {/* Video Player */}
            <div className="homepage-video-player-container" style={{marginTop: '15.058vw'}}>
                <VideoPlayer />
            </div>

            {/* Informational Text */}
            <div style={{
                marginTop: '9.942vw',
                textAlign: getTextAlign(language),
                direction: getDirection(language),
                marginLeft: '6vw',
                marginRight: '6vw'
            }}>
                <p style={{ fontSize: '5.9vw', fontWeight: '700' }}>
                    {t('freeTrialPassInfoText')}
                </p>
            </div>
        </div>
    );
}
