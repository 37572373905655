import React, { useState, useEffect, useCallback } from 'react';
import ReactPlayer from 'react-player';

export default function VideoPlayer() {
    const [videos, setVideos] = useState([]); // Store video URLs
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0); // Track the current video index
    const LOCAL_STORAGE_KEY = 'videoPlaylist';

    // Load videos from API and update local storage
    const fetchAndUpdateVideos = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/videoplayer/videos/`);
            const data = await response.json();
            const urls = data.map(video => video.url);
            if (urls.length > 0) {
                setVideos(urls);
                localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(urls)); // Update local storage
            } else {
                console.warn("No video URLs found.");
            }
        } catch (error) {
            console.error("Error fetching video URLs:", error);
        }
    };

    // Initialize videos from local storage or API
    useEffect(() => {
        const storedVideos = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
        if (storedVideos && storedVideos.length > 0) {
            setVideos(storedVideos);
        } else {
            fetchAndUpdateVideos();
        }
    }, []);

    // Refetch videos periodically or on demand
    useEffect(() => {
        const interval = setInterval(fetchAndUpdateVideos, 300000); // Update every 5 minutes
        return () => clearInterval(interval); // Clean up on component unmount
    }, []);

    // Handle video end to move to the next one or loop back
    const handleVideoEnd = useCallback(() => {
        if (videos.length > 0) {
            setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
        }
    }, [videos.length]);

    return (
        <div style={{ pointerEvents: 'none' }}>
            {videos.length > 0 ? (
                <ReactPlayer
                    url={videos[currentVideoIndex]}  // Play current video URL
                    playing={true}                   // Auto-play the video*/}
                    controls={false}                 // Hide the controls*/}
                    volume={0}                       // Set the volume to 0 to mute the video*/}
                    // width="70.029vw"                 // Set the width of the video*/}
                    width="66vw"
                    height="37vw"                    // Set the height of the video*/}
                    onEnded={handleVideoEnd}         // Handle video end*/}
                    config={{
                        youtube: {
                            playerVars: {
                                modestbranding: 1,   // Hide YouTube branding (logo)*/}
                                rel: 0,              // Disable related videos at the end*/}
                                showinfo: 0,         // Hide video title*/}
                                iv_load_policy: 3,   // Disable annotations*/}
                                fs: 0,               // Disable fullscreen button*/}
                                autohide: 1,         // Automatically hide controls when not active*/}
                                autoplay: 1          // Ensure autoplay is enabled for YouTube*/}
                            }
                        }
                    }}
                />
            ) : (
                <svg width="70.029vw" height="40vw" viewBox="0 0 479 399" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <rect x="2.5" y="2.5" width="474" height="394" rx="32.5" stroke="black" strokeWidth="5"/>
                    <line y1="346.5" x2="479" y2="346.5" stroke="black" strokeWidth="5"/>
                    <circle cx="48.5" cy="348.5" r="11.5" fill="black"/>
                    <path d="M201 140.119L287.662 187.5L201 234.881V140.119Z" stroke="black" strokeWidth="8"/>
                </svg>
            )}
        </div>
    );
}
