import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enLang from './locales/en/en.json';
import arLang from './locales/ar/ar.json';

// The translations
// You can move them to a JSON file and import them
const resources = {
  en: {
    translation: enLang,
  },
  ar: {
    translation: arLang,
  },
};

const storedLanguage = localStorage.getItem('language') || 'en';

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en',
    lng: storedLanguage, // Language to use, more info here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // You can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // If you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // React already protects from XSS
    },
  });

export default i18n;
