import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { handleLogout } from '../utils/generalFunctions';

export function Navigation() {
    const navigate = useNavigate();
    const accessToken = localStorage.getItem('access_token') || null;
    const userFirstName = localStorage.getItem('firstName') || null;
    const { t } = useTranslation();

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <div className="d-flex flex-row">
            <div className={accessToken === null ? "navbar-custom w-100" : "navbar-custom w-30"}>
                <button onClick={handleGoBack} className="btn btn-link">
                    <i className="bi bi-arrow-left" style={{fontSize: '11vw'}}></i>
                </button>

                <Link to={accessToken === null ? '/home-page/' : '/logged-in-home-page/'} className="btn btn-link">
                    <i className="bi bi-house-door" style={{fontSize: '11vw'}}></i>
                </Link>
            </div>
            <div className={accessToken === null ? "d-none" : "d-flex flex-row align-items-center w-70"} style={{marginLeft: '5vw'}}>
                <p style={{fontSize:'4vw', marginRight:'5vw'}}>{t('navigationHelloText', {
                    name: userFirstName,
                })}</p>
                <button
                    className="ms-2 btn btn-secondary"
                    style={{fontSize: '4vw'}}
                    onClick={() => {
                        handleLogout();
                    }}
                >
                    {t('navigationLogoutBtn')}
                </button>
            </div>
        </div>

    );
}
