import "bootstrap/dist/css/bootstrap.min.css";
import kioskUserImg from '../assets/images/kiosk_user_img.png';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function FreeTrialPage() {
    const language = localStorage.getItem('language') || 'en';
    const { t } = useTranslation();
    const [membershipData, setMembershipData] = useState(null);
    const [showPaymentNotification, setShowPaymentNotification] = useState(false);

    useEffect(() => {
        const checkPaymentDue = () => {
            // Get today's date
            const today = new Date();

            // Convert next_payment_date (dd/mm/yyyy) into a Date object
            const nextPaymentDateParts = membershipData.next_payment_date.split('/');
            const nextPaymentDate = new Date(
                nextPaymentDateParts[2], // Year
                nextPaymentDateParts[1] - 1, // Month (0-based index)
                nextPaymentDateParts[0] // Day
            );

            // Check if nextPaymentDate is today or in the past
            if (nextPaymentDate <= today) {
                setShowPaymentNotification(true);
            } else {
                setShowPaymentNotification(false);
            }
        };

        if (membershipData?.next_payment_date) {
            checkPaymentDue();
        }
    }, [membershipData]);

    useEffect(() => {
        const fetchMembershipData = async () => {
            try {
                const token = localStorage.getItem('access_token');
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/my-memebrship/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    alert(t('errorMessage'));
                    throw new Error('Failed to fetch membership data');
                }

                const data = await response.json();
                setMembershipData(data);

            } catch (error) {
                alert(t('errorMessage'));
                console.error('Error:', error);
            }
        };

        fetchMembershipData();
    }, []);

    const getFlexDirection = (lang) => lang === 'ar' ? 'row-reverse' : 'row';
    const getTextAlign = (lang) => lang === 'ar' ? 'right' : 'left';
    const getDirection = (lang) => lang === 'ar' ? 'rtl' : 'ltr';

    const renderLabel = (labelText) => {
        return language === 'ar' ? `: ${labelText}` : `${labelText} :`;
    };

    return (
        <div>
            {membershipData ? (
                <div>
                    <div className="d-flex justify-content-end" style={{marginTop: '2vw', marginRight: '6vw'}}>

                        <p style={{
                            fontSize: '5vw',
                            fontWeight: 'bold',
                            marginBottom: '0.5vw',
                            marginRight: language === 'ar' ? '13vw' : '7vw',
                        }}>{t('myMembershipInfoText')}</p>

                        <button className="btn btn-secondary" style={{width: '12vw', fontSize: '4vw'}}>
                            {t('myMembershipEditBtn')}
                        </button>

                    </div>

                    <div className="d-flex flex-column justify-content-center align-items-center w-100">
                        <img src={kioskUserImg} alt="user_img" style={{width: '14vw'}}/>
                    </div>

                    {showPaymentNotification && (
                        <div style={{
                            textAlign: getTextAlign(language),
                            direction: getDirection(language),
                            marginLeft: '6vw',
                            marginRight: '6vw',
                        }}>
                            <p style={{color: 'red', fontSize: '3.5vw'}}>
                                <i
                                    className="bi bi-exclamation-circle"
                                    style={{
                                        marginLeft: language === 'ar' ? '1vw' : '0',
                                        marginRight: language === 'en' ? '1vw' : '0'
                                    }}
                                ></i>
                                {t('myMembershipPaymentsNotification')}
                            </p>
                        </div>
                    )}

                    <div style={{
                        textAlign: getTextAlign(language),
                        direction: getDirection(language),
                        marginLeft: '6vw',
                        marginRight: '6vw',
                    }}>
                        <p style={{
                            fontSize: '4vw',
                            fontWeight: 'bold',
                        }}>{t('myMembershipPersonalDetails')}</p>
                    </div>

                    <div className="d-flex justify-content-between"
                         style={{width: '88vw', marginLeft: '6vw', marginBottom: '1vw'}}>
                        <div className="d-flex membership-info-block" style={{width: '50%', flexDirection: getFlexDirection(language)}}>
                            <p className='mb-0' style={{fontSize: '4vw'}}>{renderLabel(t('myMembershipFirstName'))}</p>
                            <p className='mb-0' style={{
                                marginLeft: language === 'en' ? '1vw' : '0',
                                marginRight: language === 'ar' ? '1vw' : '0',
                                fontSize: '4vw'
                            }}>{membershipData.user.first_name}</p>
                        </div>
                        <div className="d-flex membership-info-block" style={{width: '47%', flexDirection: getFlexDirection(language)}}>
                            <p style={{
                                fontSize: '4vw',
                                marginBottom: '0.3vw'
                            }}>{renderLabel(t('myMembershipLastName'))}</p>
                            <p style={{
                                marginLeft: language === 'en' ? '1vw' : '0',
                                marginRight: language === 'ar' ? '1vw' : '0',
                                fontSize: '4vw'
                            }}>{membershipData.user.last_name}</p>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between" style={{width: '88vw', marginLeft: '6vw'}}>
                        <div className="d-flex membership-info-block" style={{width: '50%', flexDirection: getFlexDirection(language)}}>
                            <p style={{fontSize: '4vw'}}>{renderLabel(t('myMembershipBirthday'))}</p>
                            <p style={{
                                marginLeft: language === 'en' ? '1vw' : '0',
                                marginRight: language === 'ar' ? '1vw' : '0',
                                fontSize: '4vw'
                            }}>{membershipData.user.birthday ? membershipData.user.birthday : '-'}</p>
                        </div>
                        <div className="d-flex membership-info-block" style={{width: '47%', flexDirection: getFlexDirection(language)}}>
                            <p style={{fontSize: '4vw'}}>{renderLabel(t('myMembershipGender'))}</p>
                            <p style={{
                                marginLeft: language === 'en' ? '1vw' : '0',
                                marginRight: language === 'ar' ? '1vw' : '0',
                                fontSize: '4vw'
                            }}>{membershipData.user.gender ? membershipData.user.gender : '-'}</p>
                        </div>
                    </div>

                    <div style={{
                        textAlign: getTextAlign(language),
                        direction: getDirection(language),
                        marginLeft: '6vw',
                        marginRight: '6vw',
                    }}>
                        <p style={{
                            fontSize: '4vw',
                            fontWeight: 'bold',
                            marginTop: '1vw'
                        }}>
                            {t('myMembershipContactDetails')}
                        </p>
                    </div>


                    <div className="d-flex membership-info-block"
                         style={{flexDirection: getFlexDirection(language), marginLeft: '6vw', marginRight: '6vw'}}>
                        <p style={{fontSize: '4vw'}}>{renderLabel(t('myMembershipEmail'))}</p>
                        <p style={{
                            marginLeft: language === 'en' ? '1vw' : '0',
                            marginRight: language === 'ar' ? '1vw' : '0',
                            fontSize: '4vw'
                        }}>{membershipData.user.email}</p>
                    </div>

                    <div className="d-flex membership-info-block mt-1"
                         style={{flexDirection: getFlexDirection(language), marginLeft: '6vw', marginRight: '6vw'}}>
                        <p style={{fontSize: '4vw'}}>{renderLabel(t('myMembershipPhoneNumber'))}</p>
                        <p style={{
                            marginLeft: language === 'en' ? '1vw' : '0',
                            marginRight: language === 'ar' ? '1vw' : '0',
                            fontSize: '4vw'
                        }}>{membershipData.user.phone_number}</p>
                    </div>

                    <div style={{
                        textAlign: getTextAlign(language),
                        direction: getDirection(language),
                        marginLeft: '6vw',
                        marginRight: '6vw',
                    }}>
                        <p style={{
                            fontSize: '4vw',
                            fontWeight: 'bold',
                            marginTop: '1vw'
                        }}>
                            {t('myMembershipMembershipDetails')}
                        </p>
                    </div>

                    <div className="d-flex membership-info-block"
                         style={{flexDirection: getFlexDirection(language), marginLeft: '6vw', marginRight: '6vw'}}>
                        <p style={{fontSize: '4vw'}}>{renderLabel(t('myMembershipHomeGym'))}</p>
                        <p style={{
                            marginLeft: language === 'en' ? '1vw' : '0',
                            marginRight: language === 'ar' ? '1vw' : '0',
                            fontSize: '4vw'
                        }}>{membershipData.home_gym.gym_name}</p>
                    </div>

                    <div className="d-flex membership-info-block mt-1"
                         style={{flexDirection: getFlexDirection(language), marginLeft: '6vw', marginRight: '6vw'}}>
                        <p style={{fontSize: '4vw'}}>{renderLabel(t('myMembershipMembershipType'))}</p>
                        <p style={{
                            marginLeft: language === 'en' ? '1vw' : '0',
                            marginRight: language === 'ar' ? '1vw' : '0',
                            fontSize: '4vw'
                        }}>{membershipData.membership_type.name}</p>
                    </div>

                    <div className="d-flex membership-info-block mt-1"
                         style={{flexDirection: getFlexDirection(language), marginLeft: '6vw', marginRight: '6vw'}}>
                        <p style={{fontSize: '4vw'}}>{renderLabel(t('myMembershipStartDate'))}</p>
                        <p style={{
                            marginLeft: language === 'en' ? '1vw' : '0',
                            marginRight: language === 'ar' ? '1vw' : '0',
                            fontSize: '4vw'
                        }}>{membershipData.start_date}</p>
                    </div>

                    <div className="d-flex membership-info-block mt-1"
                         style={{flexDirection: getFlexDirection(language), marginLeft: '6vw', marginRight: '6vw'}}>
                        <p style={{fontSize: '4vw'}}>{renderLabel(t('myMembershipAmountOfVisits'))}</p>
                        <p style={{
                            marginLeft: language === 'en' ? '1vw' : '0',
                            marginRight: language === 'ar' ? '1vw' : '0',
                            fontSize: '4vw'
                        }}>{membershipData.amount_of_visits}</p>
                    </div>


                    <div className="d-flex membership-info-block mt-1"
                         style={{flexDirection: getFlexDirection(language), marginLeft: '6vw', marginRight: '6vw'}}>
                        <p style={{fontSize: '4vw'}}>{renderLabel(t('myMembershipPaymentDate'))}</p>
                        <p style={{
                            marginLeft: language === 'en' ? '1vw' : '0',
                            marginRight: language === 'ar' ? '1vw' : '0',
                            fontSize: '4vw'
                        }}>xxx</p>
                    </div>

                    <div className="d-flex membership-info-block mt-1"
                         style={{flexDirection: getFlexDirection(language), marginLeft: '6vw', marginRight: '6vw'}}>
                        <p style={{fontSize: '4vw'}}>{renderLabel(t('myMembershipLastPaymentDate'))}</p>
                        <p style={{
                            marginLeft: language === 'en' ? '1vw' : '0',
                            marginRight: language === 'ar' ? '1vw' : '0',
                            fontSize: '4vw'
                        }}>{membershipData.last_payment_date ? membershipData.last_payment_date : '-'}</p>
                    </div>

                    <div className="d-flex membership-info-block mt-1"
                         style={{flexDirection: getFlexDirection(language), marginLeft: '6vw', marginRight: '6vw'}}>
                        <p style={{fontSize: '4vw'}}>{renderLabel(t('myMembershipLastPaymentAmount'))}</p>
                        <p style={{
                            marginLeft: language === 'en' ? '1vw' : '0',
                            marginRight: language === 'ar' ? '1vw' : '0',
                            fontSize: '4vw'
                        }}>{membershipData.last_payment_amount ? membershipData.last_payment_amount : '-'}$</p>
                    </div>

                    <div className="d-flex membership-info-block mt-1"
                         style={{flexDirection: getFlexDirection(language), marginLeft: '6vw', marginRight: '6vw'}}>
                        <p style={{fontSize: '4vw'}}>{renderLabel(t('myMembershipNextPaymentDate'))}</p>
                        <p style={{
                            marginLeft: language === 'en' ? '1vw' : '0',
                            marginRight: language === 'ar' ? '1vw' : '0',
                            fontSize: '4vw'
                        }}>{membershipData.next_payment_date ? membershipData.next_payment_date : '-'}</p>
                    </div>

                    <div className="d-flex membership-info-block mt-1"
                         style={{flexDirection: getFlexDirection(language), marginLeft: '6vw', marginRight: '6vw'}}>
                        <p style={{fontSize: '4vw'}}>{renderLabel(t('myMembershipNextPaymentAmount'))}</p>
                        <p style={{
                            marginLeft: language === 'en' ? '1vw' : '0',
                            marginRight: language === 'ar' ? '1vw' : '0',
                            fontSize: '4vw'
                        }}>{membershipData.next_payment_amount ? membershipData.next_payment_amount : '-'}$</p>
                    </div>

                    <div className="d-flex justify-content-center mt-3">
                        <Link to="/freeze-membership/">
                            <button className="btn btn-secondary me-2 membership-info-btns-bottom">
                                {t('myMembershipFreezeMembershipBtn')}
                            </button>
                        </Link>

                        <Link to="/update-details/">
                            <button className="btn btn-secondary me-2 membership-info-btns-bottom">
                                {t('myMembershipUpdateDetailsBtn')}
                            </button>
                        </Link>

                        <Link to="/upgrade/">
                            <button className="btn btn-secondary membership-info-btns-bottom">
                                {t('myMembershipUpgradeAddOnsBtn')}
                            </button>
                        </Link>
                    </div>

                    <div className="d-flex justify-content-center mt-2">
                        <Link to="/membership-cancellation/">
                            <button className="btn btn-secondary me-2 membership-info-btns-bottom">
                                {t('myMembershipMembershipCancelationBtn')}
                            </button>
                        </Link>

                        <Link to="/change-home-club/">
                            <button className="btn btn-secondary membership-info-btns-bottom">
                                {t('myMembershipChangeHomeClubBtn')}
                            </button>
                        </Link>
                    </div>

                </div>
            ) : (
                <p>Loading membership data...</p>
            )}
        </div>
    );
}
