import { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from 'react-router-dom';
import VideoPlayer from "../components/VideoPlayer";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

export default function HomePage() {
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');
    const { t } = useTranslation();

    useEffect(() => {
        localStorage.setItem('language', language);
    }, [language]);

    const handleLanguageChange = (lng) => {
        i18next.changeLanguage(lng).then(() => {
            setLanguage(lng);
        }).catch((err) => {
            console.error('Error changing language:', err);
        });
    };

    return (
        <div className="d-flex flex-column justify-content-center align-items-center w-100">
            {/* Video Player */}
            <div className="homepage-video-player-container mb-5">
                <VideoPlayer />
            </div>

            <Link to="/join-now/">
                <button className="btn btn-primary homepage-btns-size mb-5">{t('homePageJoinNowBtn')}</button>
            </Link>
            <Link to="/login/">
                <button className="btn btn-primary homepage-btns-size mb-5">{t('homePageLoginBtn')}</button>
            </Link>
            <Link to="/free-trial/">
            <button className="btn btn-primary homepage-btns-size"
                    style={{marginBottom: "10vw"}}>{t('homePageFreeTrialBtn')}</button>
            </Link>

            {/* Language Selection Buttons */}
            <div className="language-btn-container d-flex justify-content-between">
                <button
                    className={`btn homepage-btns-size me-2 ${language === 'en' ? 'btn-secondary' : 'btn-outline-secondary'}`}
                    onClick={() => handleLanguageChange('en')}
                >
                    {t('homePageEnglishBtn')}
                </button>
                <button
                    className={`btn homepage-btns-size ms-2 ${language === 'ar' ? 'btn-secondary' : 'btn-outline-secondary'}`}
                    onClick={() => handleLanguageChange('ar')}
                >
                    {t('homePageArabicBtn')}
                </button>
            </div>
        </div>
    );
}
