import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VideoPlayer from "../components/VideoPlayer";
import VirtualKeyboard from "../components/VirtualKeyboard";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function FreeTrialPage() {
    const language = localStorage.getItem('language') || 'en';
    const navigate = useNavigate();

    const { t } = useTranslation();

    // Track input values for each field
    const [inputValues, setInputValues] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: ""
    });

    const [activeInput, setActiveInput] = useState(null); // Track currently focused input
    const [layoutName, setLayoutName] = useState("default");
    const [showKeyboard, setShowKeyboard] = useState(false);

    const inputStyles = {
        label: {
            marginBottom: '1vw',
            fontSize: '3.8vw',
        },
        input: {
            marginRight: '17.4vw',
            fontSize: '3.8vw',
            textAlign: language === 'ar' ? 'right' : 'left',
            direction: language === 'ar' ? 'rtl' : 'ltr',
        },
    };

    const isSubmitDisabled = () => {
        return Object.values(inputValues).some(value => value.trim() === "");
    };

    // Automatically scroll to the bottom when the keyboard is shown
    useEffect(() => {
        if (showKeyboard) {
            window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: "smooth"
            });
        }
    }, [showKeyboard]);

    // Update the active input's value
    const handleInputChange = (value) => {
        setInputValues(prev => ({
            ...prev,
            [activeInput]: value
        }));
    };

    const handleKeyPress = (button) => {
        if (button === "{enter}") {
            setShowKeyboard(false);
        }
    };

    const getFlexDirection = (lang) => lang === 'ar' ? 'row-reverse' : 'row';
    const getTextAlign = (lang) => lang === 'ar' ? 'right' : 'left';
    const getDirection = (lang) => lang === 'ar' ? 'rtl' : 'ltr';


    const handleSubmit = () => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/free-trial/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(inputValues)
        })
        .then(async response => {
            // Check if the response is successful
            if (!response.ok) {
                const errorData = await response.json();
                const errorMessageElement = document.getElementById("error_message");
                // Handle 400 status with email validation error
                if (response.status === 400 && errorData.email) {
                    errorMessageElement.textContent = errorData.email[0];
                    errorMessageElement.style.color = "red";
                    errorMessageElement.style.fontSize = "3.5vw";
                    errorMessageElement.style.fontWeight = "bold";
                    errorMessageElement.style.marginTop = "2vw";
                    errorMessageElement.style.textAlign = "center";
                } else {
                    document.getElementById("error_message").textContent = "An error occurred. Please try again.";
                }
                alert(t('errorMessage'));
                throw new Error("Validation error");
            }
            return response.json();
        })
        .then(data => {
            // Handle successful response
            if (data.access_token && data.refresh_token) {
                localStorage.setItem("access_token", data.access_token);
                localStorage.setItem("refresh_token", data.refresh_token);
                localStorage.setItem("firstName", inputValues.firstName);

                navigate("/logged-in-home-page/");
            } else {
                alert(t('errorMessage'));
                console.error("Tokens not found in the response");
            }
        })
        .catch(error => {
            alert(t('errorMessage'));
            console.error("Error:", error);
        });
    };


    return (
        <div className="d-flex flex-column justify-content-center align-items-center w-100">
            {/* Video Player */}
            <div className="homepage-video-player-container" style={{ marginTop: '9.942vw' }}>
                <VideoPlayer />
            </div>

            {/* Informational Text */}
            <div style={{
                marginTop: '9.942vw',
                textAlign: getTextAlign(language),
                direction: getDirection(language)
            }}>
                <p style={{ fontSize: '6.5vw', fontWeight: '700' }}>
                    {t('freeTrialInfoText')}
                </p>
            </div>

            {/* Input Fields */}
            <div className="d-flex justify-content-between" style={{ width: '70.029vw', marginTop: '5vw' }}>
                {["firstName", "lastName"].map(field => (
                    <div key={field} className="d-flex flex-column free-trial-input-container"
                         style={{flexDirection: getFlexDirection(language),  width: '26.754vw'}}>
                        <label style={{...inputStyles.label, textAlign: getTextAlign(language), direction: getDirection(language)}}>
                            {field === 'firstName' ? t('freeTrialFirstName') : t('freeTrialLastName')}
                        </label>
                        <input
                            className="free-trial-input"
                            style={inputStyles.input}
                            value={inputValues[field]}
                            onFocus={() => {
                                setActiveInput(field);
                                setShowKeyboard(true);
                            }}
                            onChange={(e) => handleInputChange(e.target.value)}
                        />
                    </div>
                ))}
            </div>


            <div className="d-flex justify-content-between" style={{width: '70.029vw', marginTop: '5vw'}}>
                {["email", "phone"].map(field => (
                    <div key={field} className="d-flex flex-column free-trial-input-container"
                         style={{flexDirection: getFlexDirection(language), width: '26.754vw'}}>
                        <label style={{...inputStyles.label, textAlign: getTextAlign(language), direction: getDirection(language)}}>
                            {field === 'email' ? t('freeTrialEmail') : t('freeTrialPhone')}
                        </label>
                        {field === "phone" ? (
                            <div className="input-group">
                                <span className="input-group-text"
                                      style={{fontSize: '2vw', backgroundColor: '#D9D9D9'}}>+971</span>
                                <input
                                    className="form-control free-trial-input"
                                    type="tel"
                                    style={{fontSize: '2vw', backgroundColor: '#D9D9D9'}}
                                    value={inputValues[field]}
                                    onFocus={() => {
                                        setActiveInput(field);
                                        setShowKeyboard(true);
                                    }}
                                    onChange={(e) => handleInputChange(e.target.value)}
                                />
                            </div>
                        ) : (
                            <input
                                className="free-trial-input"
                                style={inputStyles.input}
                                value={inputValues[field]}
                                onFocus={() => {
                                    setActiveInput(field);
                                    setShowKeyboard(true);
                                }}
                                onChange={(e) => handleInputChange(e.target.value)}
                            />
                        )}
                    </div>
                ))}
            </div>

            <div id='error_message'></div>

            {/* Submit Button */}
            <button className="btn btn-primary homepage-btns-size" style={{marginTop: '8vw', marginBottom: '8vw'}} onClick={handleSubmit} disabled={isSubmitDisabled()}>
                {t('freeTrialSubmitBtn')}
            </button>

            {/* Virtual Keyboard */}
            {showKeyboard && (
                <VirtualKeyboard
                    layoutName={layoutName}
                    inputValue={inputValues[activeInput] || ""}
                    setInputValue={handleInputChange}
                    onKeyPress={handleKeyPress}
                    language={language}
                />
            )}
        </div>
    );
}
