import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from 'react-i18next';
import Sidebar from "../components/Sidebar";
import React from 'react';
import { Link } from 'react-router-dom';


export default function MembershipCancellationAfterCommitment() {
    const language = localStorage.getItem('language') || 'en';
    const { t } = useTranslation();

    const getTextAlign = (lang) => lang === 'ar' ? 'right' : 'left';

    const getDirection = (lang) => lang === 'ar' ? 'rtl' : 'ltr';

    return (
        <div className="d-flex flex-column justify-content-center align-items-center w-100">
            <div className="d-flex flex-row mt-5">

                <div style={{marginLeft: '2vw'}}>
                    <Sidebar/>
                </div>

                <div style={{
                    marginLeft: '5vw',
                    marginRight: '5vw',
                    textAlign: getTextAlign(language),
                    direction: getDirection(language)
                }}>
                    <p style={{
                        fontSize: '6vw',
                        fontWeight: 'bold',
                        textAlign: getTextAlign(language),
                    }}>
                        {t('myMembershipMembershipCancelationBtn')}
                    </p>

                    <p style={{fontSize: '5vw', marginTop: '2vw'}}>{t('membershipCnclAfterCommitText')}</p>

                </div>
            </div>
            <Link to="/membership-cancellation-success/">
                <button style={{fontSize:'4vw', marginTop:'45vw'}} className="btn btn-primary">
                    {t('freeTrialSubmitBtn')}
                </button>
            </Link>
        </div>
    );
}
