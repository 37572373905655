import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from 'react-i18next';
import Sidebar from "../components/Sidebar";
import React, { useEffect, useState } from 'react';
import VideoPlayer from "../components/VideoPlayer";
import { handleLogout } from '../utils/generalFunctions';
import { Link } from 'react-router-dom';


export default function UpdateInformationSuccess() {
    const language = localStorage.getItem('language') || 'en';
    const { t } = useTranslation();

    const getTextAlign = (lang) => lang === 'ar' ? 'right' : 'left';

    return (
        <div className="d-flex flex-column">
            <div className="d-flex flex-row mt-5">

                <div style={{marginLeft: '2vw'}}>
                    <Sidebar/>
                </div>
                <div style={{width:'30vw'}}>
                    <VideoPlayer/>
                </div>

            </div>
            <div className="d-flex align-items-center flex-column">

                <div style={{marginLeft: '12vw', marginRight: '12vw', marginTop: '10vw'}}>
                    <p style={{
                        fontSize: '6vw',
                        fontWeight: 'bold',
                        textAlign: getTextAlign(language),
                    }}>
                        {t('updateInformationSuccessText1')}
                    </p>

                    <p style={{
                        fontSize: '4vw',
                        textAlign: getTextAlign(language),
                    }}>
                        {t('updateInformationSuccessText2')}
                    </p>
                </div>

                <div className="d-flex flex-row" style={{marginTop: '30vw'}}>
                    <Link to="/logged-in-home-page/">
                        <button style={{fontSize: '4vw', marginRight: '4vw'}}
                                className="btn btn-primary">{t('homePage')}</button>
                    </Link>
                    <button onClick={handleLogout} style={{fontSize: '4vw'}}
                            className="btn btn-secondary">{t('navigationLogoutBtn')}</button>
                </div>
            </div>
        </div>
    );
}
