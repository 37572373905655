import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from 'react-router-dom';
import VideoPlayer from "../components/VideoPlayer";
import { useTranslation } from 'react-i18next';


export default function LoggedInHomePage() {
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');
    const userFirstName = localStorage.getItem('firstName');
    const { t } = useTranslation();

    const getTextAlign = (lang) => lang === 'ar' ? 'right' : 'left';
    const getDirection = (lang) => lang === 'ar' ? 'rtl' : 'ltr';

    return (
        <div className="d-flex flex-column justify-content-center align-items-center w-100">
            {/* Video Player */}
            <div className="mb-5" style={{ marginTop: '3vw' }}>
                <VideoPlayer />
            </div>

            <div style={{
                textAlign: getTextAlign(language),
                direction: getDirection(language)
            }}>
                <p style={{
                    fontSize: '5.9vw',
                    fontWeight: '700',
                    marginTop: '3.5vw',
                    marginLeft: '6vw',
                    marginRight: '6vw'
                }}>
                    {t('loggedInHomePageWelcomeText', {
                        name: userFirstName,
                    })}
                </p>
            </div>
            <Link to="/my-membership/" style={{marginTop: '6vw'}}>
                <button className="btn btn-primary homepage-btns-size mb-5">{t('loggedInHomePageMyMembershipBtn')}</button>
            </Link>
            <Link to="/upgrade/">
                <button className="btn btn-primary homepage-btns-size mb-5">{t('loggedInHomePageUpgradeBtn')}</button>
            </Link>
            <Link>
                <button className="btn btn-primary homepage-btns-size mb-5">{t('loggedInHomePageReferABuddyBtn')}</button>
            </Link>
            <Link>
                <button className="btn btn-primary homepage-btns-size mb-5">{t('loggedInHomePagePaymentsBtn')}</button>
            </Link>
        </div>
    );
}
