import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from 'react-i18next';
import Sidebar from "../components/Sidebar";
import React, { useEffect, useState } from 'react';
import {UPGRADE_ADD_ONS_GET_DATA, UPGRADE_ADD_ONS_UPDATE_DATA} from "../Endpoints";
import { useNavigate } from 'react-router-dom';


export default function UpgradeAddOnsPage() {
    const language = localStorage.getItem('language') || 'en';
    const { t } = useTranslation();
    const [upgradeAddOnsData, setUpgradeAddOnsData] = useState(null);
    const [selectedMembership, setSelectedMembership] = useState('');
    const [membershipPrice, setMembershipPrice] = useState(null);
    const [selectedAddOns, setSelectedAddOns] = useState(null);
    const navigate = useNavigate();

    const getTextAlign = (lang) => lang === 'ar' ? 'right' : 'left';

    const getDirection = (lang) => lang === 'ar' ? 'rtl' : 'ltr';

    useEffect(() => {
        const fetchUpgradeData = async () => {
            try {
                const token = localStorage.getItem('access_token');
                const response = await fetch(UPGRADE_ADD_ONS_GET_DATA, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    alert(t('errorMessage'));
                    throw new Error('Failed to fetch membership data');
                }

                const data = await response.json();
                setUpgradeAddOnsData(data);
                setSelectedAddOns(data.user_add_ons.map((userAddOn) => userAddOn.name));

            } catch (error) {
                console.error('Error:', error);
                alert(t('errorMessage'));
            }
        };
        fetchUpgradeData();
    }, []);

    const handleChange = (event) => {
        const selectedName = event.target.value;

        // Find the selected membership object
        const selectedMembershipObj = upgradeAddOnsData.all_membership_types.find(
            (membership) => membership.name === selectedName
        );

        // Update state
        setSelectedMembership(selectedName);
        setMembershipPrice(selectedMembershipObj?.price || null);
    };

      // Handle checkbox change
  const handleCheckboxChange = (addOnName) => {
    setSelectedAddOns((prevSelected) =>
      prevSelected.includes(addOnName)
        ? prevSelected.filter((name) => name !== addOnName)
        : [...prevSelected, addOnName]
    );
  };


    const fetchUpgradeAddOsnUpdate = async () => {
        const token = localStorage.getItem('access_token');

        try {
            const response = await fetch(UPGRADE_ADD_ONS_UPDATE_DATA, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ new_membership_type: selectedMembership, selected_add_ons: selectedAddOns})
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
                alert(t('errorMessage'));
            }

            navigate('/freeze-membership-pending-payment/');
        } catch (error) {
            console.error('Error changing gym:', error);
            alert(t('errorMessage'));
        }
    };


    return (
        <div>
            {upgradeAddOnsData ? (
                <div className="d-flex flex-column justify-content-center align-items-center w-100">
                    <div className="d-flex flex-row mt-5 w-100">

                        <div style={{marginLeft: '2vw'}}>
                            <Sidebar/>
                        </div>

                        <div style={{
                            marginLeft: '5vw',
                            marginRight: '5vw',
                            textAlign: getTextAlign(language),
                            direction: getDirection(language),
                            width: '100%'
                        }}>
                            <p style={{
                                fontSize: '6vw',
                                fontWeight: 'bold',
                                textAlign: getTextAlign(language),
                            }}>
                                {t('upgrades')}
                            </p>

                            <p style={{
                                fontSize: '4vw',
                                marginTop: '2vw',
                                fontWeight: 'bold'
                            }}>{t('upgradesCurrentMembership')}</p>

                            <div className="d-flex mt-1"
                                 style={{
                                     marginLeft: '2vw',
                                     marginRight: '2vw'
                                 }}>
                                <p style={{fontSize: '4vw'}}>{t('upgradesName')}</p>
                                <p style={{
                                    marginLeft: language === 'en' ? '1vw' : '0',
                                    marginRight: language === 'ar' ? '1vw' : '0',
                                    fontSize: '4vw'
                                }}>{upgradeAddOnsData.first_name} {upgradeAddOnsData.last_name}</p>
                            </div>

                            <div className="d-flex mt-1"
                                 style={{
                                     marginLeft: '2vw',
                                     marginRight: '2vw'
                                 }}>
                                <p style={{fontSize: '4vw'}}>{t('upgradesMembershipType')}</p>
                                <p style={{
                                    marginLeft: language === 'en' ? '1vw' : '0',
                                    marginRight: language === 'ar' ? '1vw' : '0',
                                    fontSize: '4vw'
                                }}>{upgradeAddOnsData.membership_type_name}</p>
                            </div>

                            <div className="d-flex mt-1"
                                 style={{
                                     marginLeft: '2vw',
                                     marginRight: '2vw'
                                 }}>
                                <p style={{fontSize: '4vw'}}>{t('upgradesMembershipPrice')}</p>
                                <p style={{
                                    marginLeft: language === 'en' ? '1vw' : '0',
                                    marginRight: language === 'ar' ? '1vw' : '0',
                                    fontSize: '4vw'
                                }}>{upgradeAddOnsData.membership_type_price}</p>
                            </div>

                            <p style={{
                                fontSize: '4vw',
                                marginTop: '2vw',
                                fontWeight: 'bold'
                            }}>{t('upgradesNewMembership')}</p>

                            <div style={{marginTop: '2vw'}}>
                                <select
                                    id="membershipDropdown"
                                    value={selectedMembership}
                                    onChange={handleChange}
                                    style={{
                                        marginLeft: "2vw",
                                        marginRight: '2vw',
                                        padding: "1vw",
                                        fontSize: '4vw',
                                        borderRadius: '2vw',
                                    }}
                                >
                                    {/* Placeholder option */}
                                    <option value="" disabled>
                                        {t('upgradesMembershipTypes')}
                                    </option>

                                    {/* Actual membership options */}
                                    {upgradeAddOnsData.all_membership_types.map((membership, index) => (
                                        <option key={index} value={membership.name} style={{fontSize: '4vw'}}>
                                            {membership.name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="d-flex mt-1"
                                 style={{
                                     marginLeft: '2vw',
                                     marginRight: '2vw'
                                 }}>
                                <p style={{fontSize: '4vw'}}>{t('upgradesMembershipPrice')}</p>
                                <p style={{
                                    marginLeft: language === 'en' ? '1vw' : '0',
                                    marginRight: language === 'ar' ? '1vw' : '0',
                                    fontSize: '4vw'
                                }}>{membershipPrice ? membershipPrice : '-'}</p>
                            </div>

                            <div className="d-flex mt-1"
                                 style={{
                                     marginLeft: '2vw',
                                     marginRight: '2vw'
                                 }}>
                                <p style={{fontSize: '4vw'}}>{t('upgradesVat')}</p>
                                <p style={{
                                    marginLeft: language === 'en' ? '1vw' : '0',
                                    marginRight: language === 'ar' ? '1vw' : '0',
                                    fontSize: '4vw'
                                }}>23%</p>
                            </div>

                            <div className="d-flex mt-1"
                                 style={{
                                     marginLeft: '2vw',
                                     marginRight: '2vw'
                                 }}>
                                <p style={{fontSize: '4vw'}}>{t('upgradesTotal')}</p>
                                <p style={{
                                    marginLeft: language === 'en' ? '1vw' : '0',
                                    marginRight: language === 'ar' ? '1vw' : '0',
                                    fontSize: '4vw'
                                }}>{membershipPrice
                                    ? `${(parseInt(membershipPrice, 10) + parseInt(membershipPrice, 10) * 0.23).toFixed(2)}`
                                    : '-'}
                                </p>
                            </div>


                        </div>
                    </div>

                    <div style={{marginTop: '5vw'}}>
                        <p style={{
                            fontSize: '6vw',
                            fontWeight: 'bold',
                            textAlign: getTextAlign(language),
                        }}>
                            {t('upgradesAddOns')}
                        </p>
                    </div>

                    <div>
                        {upgradeAddOnsData.all_add_ons.map((addOn) => (
                            <div
                                key={addOn.name}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontSize: '4vw',
                                    marginBottom: '1vw',
                                    flexDirection: language === 'ar' ? 'row-reverse' : 'row',
                                }}
                            >
                                <input
                                    type="checkbox"
                                    style={{
                                        width: '4vw',
                                        height: '4vw',
                                        marginRight: '1vw',
                                    }}
                                    name={addOn.name}
                                    value={addOn.name}
                                    checked={selectedAddOns.includes(addOn.name)}
                                    onChange={() => handleCheckboxChange(addOn.name)}
                                />
                                <label htmlFor={addOn.name} style={{marginRight: '2vw'}}>
                                    {addOn.name}
                                </label>
                            </div>
                        ))}
                    </div>


                    <div className="d-flex flex-row" style={{marginTop: '8vw'}}>
                        <button onClick={fetchUpgradeAddOsnUpdate} style={{fontSize: '4vw'}} className="btn btn-primary">
                            {t('upgradesConfirmBtn')}
                        </button>
                    </div>
                </div>
            ) : (
                <p>Loading membership data...</p>
            )}
        </div>
    );
}
