import "bootstrap/dist/css/bootstrap.min.css";
import kioskMembershipImg from '../assets/images/kiosk_membership_img.jpg';
import { useTranslation } from 'react-i18next';


export default function JoinNowPage() {
    const language = localStorage.getItem('language') || 'en';

    const { t } = useTranslation();

    const inputStyles = {
        label: {
            marginBottom: '1vw',
            fontSize: '3.8vw',
        },
        input: {
            marginRight: '17.4vw',
        },
    };
    const getTextAlign = (lang) => lang === 'ar' ? 'right' : 'left';
    const getDirection = (lang) => lang === 'ar' ? 'rtl' : 'ltr';

    return (
        <div style={{
            textAlign: getTextAlign(language),
            direction: getDirection(language)
        }}>
            <p style={{fontSize: '5.9vw', fontWeight: '700', marginTop: '3.5vw', marginLeft: '6vw', marginRight: '6vw'}}>
                {t('joinNowMembershipText')}
            </p>
            <div className="d-flex flex-column justify-content-center align-items-center w-100">
                <img src={kioskMembershipImg} alt="QR code" style={{width: '67.496vw'}}/>
            </div>
            <p style={{fontSize: '5.9vw', fontWeight: '700', marginTop: '4.5vw', marginLeft: '6vw', marginRight: '6vw'}}>
                {t('joinNowQRCodeText')}
            </p>
            <div className="d-flex flex-column justify-content-center align-items-center w-100 mt-5">
                <img
                    src='https://t1.gstatic.com/licensed-image?q=tbn:ANd9GcTFRaX872E1iX4jlcXwLY01J-fRIap_Bnveev0X5UgEGtUHrSALgmbenlDAx3cryQFr'
                    alt="QR code" style={{width: '26.94vw'}}/>
            </div>
        </div>
    );
}
