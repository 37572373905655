import "bootstrap/dist/css/bootstrap.min.css";
import VideoPlayer from "../components/VideoPlayer";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function MemberPage() {
    const language = localStorage.getItem('language') || 'en';
    const { t } = useTranslation();

    const getTextAlign = (lang) => lang === 'ar' ? 'right' : 'left';
    const getDirection = (lang) => lang === 'ar' ? 'rtl' : 'ltr';

    return (
        <div className="d-flex flex-column justify-content-center align-items-center w-100">
            {/* Video Player */}
            <div className="homepage-video-player-container" style={{marginTop: '6.725vw'}}>
                <VideoPlayer />
            </div>

            {/* Informational Text */}
            <div style={{
                marginTop: '4.094vw',
                textAlign: getTextAlign(language),
                direction: getDirection(language),
                height: '17.836vw',
                marginLeft: '6vw',
                marginRight: '6vw',
            }}>
                <p style={{ fontSize: '5.9vw', fontWeight: '700'}}>
                    {t('memberPageInfoText')}
                </p>
            </div>

            <Link to="/#/">
                <button className="btn btn-primary homepage-btns-size mb-5 mt-3">{t('memberPageMyMembershipBtn')}</button>
            </Link>

            <Link to="/#/">
                <button className="btn btn-primary homepage-btns-size mb-5">{t('memberPagePaymentsBtn')}</button>
            </Link>

            <Link to="/#/">
                <button className="btn btn-primary homepage-btns-size mb-5">{t('memberPageReferABuddyBtn')}</button>
            </Link>

            <Link to="/#/">
                <button className="btn btn-primary homepage-btns-size mb-5">{t('memberPageUpgradeBtn')}</button>
            </Link>
        </div>
    );
}
