import "bootstrap/dist/css/bootstrap.min.css";
import VideoPlayer from "../components/VideoPlayer";
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function LoginPage() {
    const language = localStorage.getItem('language') || 'en';
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [qrData, setQrData] = useState('');

    // Mock QR scanner data
    const mockQrData = {
        firstName: "John",
        lastName: "Doe",
        dob: "1990-01-01",
        gender: "M",
        email: "john.doe@example.com",
        phone: "+123456789"
    };

    const handleScanQr = () => {
        const qrString = JSON.stringify(mockQrData);
        setQrData(qrString);

        const parsedData = JSON.parse(qrString);

        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/login/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(parsedData)
        })
        .then(response => response.json())
        .then(data => {
            if (data.access_token && data.refresh_token) {
                localStorage.setItem("access_token", data.access_token);
                localStorage.setItem("refresh_token", data.refresh_token);
                localStorage.setItem("firstName", mockQrData.firstName);

                navigate("/logged-in-home-page/");
            } else {
                alert(t('errorMessage'));
                console.error("Tokens not found in the response");
            }
        })
        .catch(error => {
            alert(t('errorMessage'));
            console.error("Error:", error);
        });
    };

    return (
        <div className="d-flex flex-column justify-content-center align-items-center w-100">
            {/* Video Player */}
            <div className="homepage-video-player-container" style={{ marginTop: '15.058vw' }}>
                <VideoPlayer />
            </div>

            {/* QR Code Scan Text */}
            <div
                style={{
                    marginTop: '11.842vw',
                    width: '72.076vw',
                    height: '29.24vw',
                    textAlign: language === 'ar' ? 'right' : 'left',
                    direction: language === 'ar' ? 'rtl' : 'ltr'
                }}
            >
                <p style={{ fontSize: '6.5vw', fontWeight: '700' }}>
                    {t('loginQRText')}
                </p>
            </div>

            <div style={{ marginTop: '9vw', width: '72.076vw' }}>
                <i className="bi bi-arrow-down" style={{ fontSize: '13.5vw' }}></i>
            </div>

            {/* Mock QR data output */}
            <input
                type="hidden"
                value={qrData}
                readOnly
            />

            {/* Scan QR Button */}
            <div style={{ marginTop: '9vw', width: '72.076vw' }}>
                <button
                    className="btn btn-primary"
                    onClick={handleScanQr}
                    style={{ fontSize: '5vw', fontWeight: '700' }}
                >
                    {t('scanQR')}
                </button>
            </div>
        </div>
    );
}
