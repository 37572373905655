import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from 'react-i18next';
import Sidebar from "../components/Sidebar";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function ChangeHomeClubPage() {
    const language = localStorage.getItem('language') || 'en';
    const { t } = useTranslation();
    const [changeHomeClubData, setChangeHomeClubData] = useState(null);
    const [isEligibleClubChange, setIsEligibleClubChange] = useState(false);
    const navigate = useNavigate();

    const [selectedGym, setSelectedGym] = useState(null);

    const handleGymSelect = (gymName) => {
        setSelectedGym(gymName);
    };

    const getTextAlign = (lang) => lang === 'ar' ? 'right' : 'left';
    const getDirection = (lang) => lang === 'ar' ? 'rtl' : 'ltr';
    const getFlexDirection = (lang) => lang === 'ar' ? 'row-reverse' : 'row';

    const renderLabel = (labelText) => {
        return language === 'ar' ? `: ${labelText}` : `${labelText} :`;
    };

    function isEligibleToChangeClub(lastChangeDateStr, membershipType) {
        // If the membership type is "Plus", the user is always eligible
        if (membershipType === "Plus") {
            return true;
        }

        // If membership type is not "Plus" and `lastChangeDateStr` is null, the user is eligible
        if (!lastChangeDateStr) {
            return true;
        }

        // Parse the date from the input (dd/mm/yyyy)
        const [day, month, year] = lastChangeDateStr.split("/").map(Number);
        const lastChangeDate = new Date(year, month - 1, day); // Month is zero-based

        // Get the current date
        const currentDate = new Date();

        // Compare years first
        if (currentDate.getFullYear() > lastChangeDate.getFullYear()) {
            if (
                currentDate.getFullYear() - lastChangeDate.getFullYear() > 1 || // More than a year passed
                currentDate.getMonth() > lastChangeDate.getMonth() || // Same year, but month passed
                (currentDate.getMonth() === lastChangeDate.getMonth() &&
                    currentDate.getDate() >= lastChangeDate.getDate()) // Same month, but day passed
            ) {
                return true;
            }
        }

        // Otherwise, one year has not passed
        return false;
    }


    const fetchChangeGymPageData = async () => {
        try {
            const token = localStorage.getItem('access_token');
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/change-home-gym/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                alert(t('errorMessage'));
                throw new Error('Failed to fetch membership data');
            }
                const data = await response.json();
                setChangeHomeClubData(data);
                setSelectedGym(data.home_gym.gym_name)
                const eligibility = isEligibleToChangeClub(data.last_gym_change, data.membership_type.name);
                setIsEligibleClubChange(eligibility);

            } catch (error) {
                alert(t('errorMessage'));
                console.error('Error:', error);
            }
    };

    const fetchGymChangeUpdate = async () => {
        const token = localStorage.getItem('access_token');

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/change-home-club-update/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ gym_name: selectedGym })
            });

            if (!response.ok) {
                alert(t('errorMessage'));
                throw new Error(`Error: ${response.statusText}`);
            }

            const data = await response.json();
            navigate("/change-home-club-success/");
        } catch (error) {
            alert(t('errorMessage'));
            console.error('Error changing gym:', error);
        }
    };

    useEffect(() => {
        fetchChangeGymPageData();
    }, []);

    return (
        <div>
            {changeHomeClubData ? (
                <div className="d-flex flex-column justify-content-center align-items-center w-100">
                    <div className="d-flex flex-row mt-5">

                        <div style={{marginLeft: '2vw'}}>
                            <Sidebar/>
                        </div>

                        <div style={{marginLeft: '5vw', marginRight: '5vw'}}>
                            <p style={{
                                fontSize: '6vw',
                                fontWeight: 'bold',
                                textAlign: getTextAlign(language),
                            }}>
                                {t('myMembershipChangeHomeClubBtn')}
                            </p>
                            <p style={{
                                fontSize: '4vw',
                                marginTop: '2vw',
                                textAlign: getTextAlign(language),
                            }}>
                                {t('changeHomeClubInfoText1')}
                            </p>
                            <p style={{
                                fontSize: '4vw',
                                marginTop: '2vw',
                                textAlign: getTextAlign(language),
                            }}>
                                {t('changeHomeClubInfoText2')}
                            </p>

                            <div className="d-flex"
                                 style={{flexDirection: getFlexDirection(language), marginTop: '2vw'}}>
                                <p style={{fontSize: '4vw'}}>{renderLabel(t('myMembershipMembershipType'))}</p>
                                <p style={{
                                    marginLeft: language === 'en' ? '1vw' : '0',
                                    marginRight: language === 'ar' ? '1vw' : '0',
                                    fontSize: '4vw'
                                }}>{changeHomeClubData.membership_type.name}</p>
                            </div>

                            <div className="d-flex"
                                 style={{flexDirection: getFlexDirection(language), marginTop: '2vw'}}>
                                <p style={{fontSize: '4vw'}}>{renderLabel(t('myMembershipHomeGym'))}</p>
                                <p style={{
                                    marginLeft: language === 'en' ? '1vw' : '0',
                                    marginRight: language === 'ar' ? '1vw' : '0',
                                    fontSize: '4vw',
                                }}>{changeHomeClubData.home_gym.gym_name}</p>
                            </div>

                            <div className="d-flex flex-column" style={{flexDirection: getFlexDirection(language), marginTop: '2vw', direction: getDirection(language)}}>
                                <p style={{fontSize: '4vw'}}>{renderLabel(t('myMembershipChangeHomeClubBtn'))}</p>
                                <div style={{width: '100%', marginTop: '2vw'}}>
                                    <select
                                        onChange={(e) => handleGymSelect(e.target.value)}
                                        style={{
                                            width: '50%',
                                            padding: '1vw',
                                            fontSize: '4vw',
                                        }}
                                        value={selectedGym}
                                    >
                                        {changeHomeClubData.gyms.map((gym, index) => (
                                            <option
                                                key={index}
                                                value={gym.gym_name}
                                                style={{
                                                    fontSize: '15px',
                                                }}
                                            >
                                                {gym.gym_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button
                        onClick={fetchGymChangeUpdate}
                        className='btn btn-primary'
                        style={{
                            marginTop: '50vw',
                            fontSize: '5vw',
                        }}
                        disabled={!isEligibleClubChange}
                    >
                        {t('myMembershipChangeHomeClubBtn')}
                    </button>
                </div>
            ) : (
                <p>Loading membership data...</p>
            )}
        </div>
    );
}
