import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from 'react-i18next';
import Sidebar from "../components/Sidebar";
import React, { useEffect, useState } from 'react';
import VideoPlayer from "../components/VideoPlayer";
import { handleLogout } from '../utils/generalFunctions';
import { Link } from 'react-router-dom';


export default function MembershipCancellationBeforeCommitment() {
    const language = localStorage.getItem('language') || 'en';
    const { t } = useTranslation();

    const getTextAlign = (lang) => lang === 'ar' ? 'right' : 'left';

    const getDirection = (lang) => lang === 'ar' ? 'rtl' : 'ltr';

    return (
        <div>
            <div className="d-flex flex-column justify-content-center align-items-center w-100">
                <div className="d-flex flex-row mt-5">

                    <div style={{marginLeft: '2vw'}}>
                        <Sidebar/>
                    </div>

                    <div style={{
                        marginLeft: '5vw',
                        marginRight: '5vw',
                        textAlign: getTextAlign(language),
                        direction: getDirection(language)
                    }}>
                        <p style={{
                            fontSize: '6vw',
                            fontWeight: 'bold',
                            textAlign: getTextAlign(language),
                        }}>
                            {t('myMembershipMembershipCancelationBtn')}
                        </p>

                        <p style={{fontSize: '5vw', marginTop: '2vw'}}>{t('membershipCnclBeforeCommitText1')}</p>

                        <p style={{fontSize: '4vw', marginTop: '2vw'}}>
                            {t('membershipCnclBeforeCommitText2', {
                                date: "10/11/2025",
                            })}
                        </p>

                        <p style={{fontSize: '4vw', marginTop: '2vw'}}>{t('membershipCnclBeforeCommitText3')}</p>

                        <p style={{fontSize: '4vw', direction:'ltr'}}>{language === 'en' ? '•' : ''} {t('membershipCnclReason1')} {language === 'ar' ? '•' : ''}</p>
                        <p style={{fontSize: '4vw', direction:'ltr'}}>{language === 'en' ? '•' : ''} {t('membershipCnclReason2')} {language === 'ar' ? '•' : ''}</p>
                        <p style={{fontSize: '4vw', direction:'ltr'}}>{language === 'en' ? '•' : ''} {t('membershipCnclReason3')} {language === 'ar' ? '•' : ''}</p>
                        <p style={{fontSize: '4vw', direction:'ltr'}}>{language === 'en' ? '•' : ''} {t('membershipCnclReason4')} {language === 'ar' ? '•' : ''}</p>

                        <p style={{fontSize: '4vw', marginTop: '2vw'}}>{t('membershipCnclBeforeCommitText4')}</p>
                    </div>
                </div>

                <div className="d-flex flex-row" style={{marginTop: '8vw'}}>
                    <Link to="/logged-in-home-page/">
                        <button style={{fontSize: '4vw', marginRight: '4vw'}} className="btn btn-primary">{t('homePage')}</button>
                    </Link>
                    <button onClick={handleLogout} style={{fontSize: '4vw'}} className="btn btn-secondary">{t('navigationLogoutBtn')}</button>
                </div>
            </div>
        </div>
    );
}
