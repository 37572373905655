import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from 'react-i18next';
import Sidebar from "../components/Sidebar";
import React, { useEffect, useState } from 'react';
import VirtualKeyboard from "../components/VirtualKeyboard";
import DatePicker, { registerLocale } from 'react-datepicker';
import { enGB, arSA } from 'date-fns/locale';
import '../assets/styles/updateInformationPage.css';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import {UPDATE_INFORMATION_GET_DATA, UPDATE_INFORMATION_UPDATE_DATA} from "../Endpoints";

export default function UpdateInformationPage() {
    const language = localStorage.getItem('language') || 'en';
    const { t } = useTranslation();
    const [usersData, setUsersData] = useState(null);
    const [selectedGym, setSelectedGym] = useState(null);
    const [isEligibleClubChange, setIsEligibleClubChange] = useState(false);
    const navigate = useNavigate();

    // Track input values for each field
    const [inputValues, setInputValues] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: ""
    });

    const [activeInput, setActiveInput] = useState(null); // Track currently focused input
    const [layoutName, setLayoutName] = useState("default");
    const [showKeyboard, setShowKeyboard] = useState(false);

    registerLocale('en', enGB);
    registerLocale('ar', arSA);

    const handleGymSelect = (gymName) => {
        setSelectedGym(gymName);
    };

    // Automatically scroll to the bottom when the keyboard is shown
    useEffect(() => {
        if (showKeyboard) {
            window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: "smooth"
            });
        }
    }, [showKeyboard]);


    useEffect(() => {
        if (usersData !== null && selectedGym !== null) {
            const eligibility = isEligibleToChangeClub(
                usersData.membership.last_home_club_change,
                usersData.membership.membership_type.name
            );
            setIsEligibleClubChange(eligibility);
        }
    }, [selectedGym]);

    // Update the active input's value
    const handleInputChange = (value) => {
        setInputValues(prev => ({
            ...prev,
            [activeInput]: value
        }));
    };

    const handleKeyPress = (button) => {
        if (button === "{enter}") {
            setShowKeyboard(false);
        }
    };

    const getTextAlign = (lang) => lang === 'ar' ? 'right' : 'left';
    const getDirection = (lang) => lang === 'ar' ? 'rtl' : 'ltr';
    const getFlexDirection = (lang) => lang === 'ar' ? 'row-reverse' : 'row';

    const renderLabel = (labelText) => {
        return language === 'ar' ? `: ${labelText}` : `${labelText} :`;
    };

    const inputStyles = {
        label: {
            marginBottom: '1vw',
            fontSize: '3vw',
        },
        input: {
            textAlign: language === 'ar' ? 'right' : 'left',
            direction: language === 'ar' ? 'rtl' : 'ltr',
        },
    };

    useEffect(() => {
        fetchUpdateDeatilsPageData();
    }, []);

    function isEligibleToChangeClub(lastChangeDateStr, membershipType) {
        const errorMessageElement = document.getElementById("isEligibleToChangeCLubErrorMessage");

        if (selectedGym === usersData.membership.home_gym.gym_name) {
            errorMessageElement.style.display = 'none';
            return true;
        }
        // If the membership type is "Plus", the user is always eligible
        if (membershipType === "Plus") {
            errorMessageElement.style.display = 'none';
            return true;
        }

        // If membership type is not "Plus" and `lastChangeDateStr` is null, the user is eligible
        if (!lastChangeDateStr) {
            errorMessageElement.style.display = 'none';
            return true;
        }

        // Parse the date from the input (dd/mm/yyyy)
        const [day, month, year] = lastChangeDateStr.split("/").map(Number);
        const lastChangeDate = new Date(year, month - 1, day); // Month is zero-based

        // Get the current date
        const currentDate = new Date();

        // Compare years first
        if (currentDate.getFullYear() > lastChangeDate.getFullYear()) {
            if (
                currentDate.getFullYear() - lastChangeDate.getFullYear() > 1 || // More than a year passed
                currentDate.getMonth() > lastChangeDate.getMonth() || // Same year, but month passed
                (currentDate.getMonth() === lastChangeDate.getMonth() &&
                    currentDate.getDate() >= lastChangeDate.getDate()) // Same month, but day passed
            ) {
                errorMessageElement.style.display = "none";
                return true;
            }
        }

        // Otherwise, one year has not passed
        errorMessageElement.style.display = "block";
        return false;
    }


    const fetchUpdateDeatilsPageData = async () => {
        try {
            const token = localStorage.getItem('access_token');
            const response = await fetch(UPDATE_INFORMATION_GET_DATA, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

        if (!response.ok) {
            alert(t('errorMessage'));
            throw new Error('Failed to fetch membership data');
        }
            const data = await response.json();
            setUsersData(data);
            console.log(data);
            setInputValues({
                firstName: data.user.first_name,
                lastName: data.user.last_name,
                email: data.user.email,
                phone: data.user.phone_number.substring(4)
            });
            setSelectedGym(data.membership.home_gym.gym_name);

        } catch (error) {
            alert(t('errorMessage'));
            console.error('Error:', error);
        }
    };

    const fetchGymChangeUpdate = async () => {
        const token = localStorage.getItem('access_token');

        // Parse the birthday string into a Date object
        let birthdayDate;
        if (usersData.user.birthday.includes('/')) {
            // Assume "dd/MM/yyyy" format
            const [day, month, year] = usersData.user.birthday.split('/');
            birthdayDate = new Date(year, month - 1, day); // Month is 0-indexed
        } else if (usersData.user.birthday.includes('-')) {
            // Assume "yyyy-MM-dd" format
            const [year, month, day] = usersData.user.birthday.split('-');
            birthdayDate = new Date(year, month - 1, day); // Month is 0-indexed
        } else {
            throw new Error('Invalid date format');
        }

        // Format the Date object as "yyyy-MM-dd"
        const formattedBirthdayDate = format(birthdayDate, 'yyyy-MM-dd');

        try {
            const response = await fetch(UPDATE_INFORMATION_UPDATE_DATA, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    gym_name: selectedGym,
                    first_name: inputValues.firstName,
                    last_name: inputValues.lastName,
                    email: inputValues.email,
                    phone: inputValues.phone,
                    gender: usersData.user.gender,
                    birthday: formattedBirthdayDate
                })
            });

            if (!response.ok) {
                alert(t('errorMessage'));
                throw new Error(`Error: ${response.statusText}`);
            }

            const data = await response.json();
            navigate("/update-details-success/");
        } catch (error) {
            alert(t('errorMessage'));
            console.error('Error changing gym:', error);
        }
    };

    return (
        <div>
            {usersData ? (
                <div className="d-flex flex-column justify-content-center align-items-center w-100">
                    <div className="d-flex flex-row mt-5">

                        <div style={{marginLeft: '2vw'}}>
                            <Sidebar/>
                        </div>

                        <div style={{marginLeft: '5vw', marginRight: '5vw'}}>
                            <p style={{
                                fontSize: '6vw',
                                fontWeight: 'bold',
                                textAlign: getTextAlign(language),
                            }}>
                                {t('myMembershipUpdateDetailsBtn')}
                            </p>

                            {/* Input Fields */}
                            <div className="d-flex justify-content-between" style={{marginTop: '5vw'}}>
                                {["firstName", "lastName"].map(field => (
                                    <div key={field} className="d-flex flex-column free-trial-input-container"
                                         style={{
                                             flexDirection: getFlexDirection(language),
                                             width: '26.754vw', ...inputStyles.label
                                         }}>
                                        <label style={{
                                            textAlign: getTextAlign(language),
                                            direction: getDirection(language)
                                        }}>
                                            {field === 'firstName' ? t('freeTrialFirstName') : t('freeTrialLastName')}
                                        </label>
                                        <input
                                            className="free-trial-input"
                                            style={inputStyles.input}
                                            value={inputValues[field]}
                                            onFocus={() => {
                                                setActiveInput(field);
                                                setShowKeyboard(true);
                                            }}
                                            onChange={(e) => handleInputChange(e.target.value)}
                                        />
                                    </div>
                                ))}
                            </div>

                            <div className="d-flex justify-content-between"
                                 style={{marginTop: '3vw'}}>
                                {["email", "phone"].map(field => (
                                    <div key={field} className="d-flex flex-column free-trial-input-container"
                                         style={{flexDirection: getFlexDirection(language), width: '26.754vw'}}>
                                        <label style={{
                                            ...inputStyles.label,
                                            textAlign: getTextAlign(language),
                                            direction: getDirection(language)
                                        }}>
                                            {field === 'email' ? t('freeTrialEmail') : t('freeTrialPhone')}
                                        </label>
                                        {field === "phone" ? (
                                            <div className="input-group">
                                            <span className="input-group-text"
                                                  style={{fontSize: '2vw', backgroundColor: '#D9D9D9'}}>+971</span>
                                                <input
                                                    className="form-control free-trial-input"
                                                    type="tel"
                                                    style={{fontSize: '3vw', backgroundColor: '#D9D9D9'}}
                                                    value={inputValues[field]}
                                                    onFocus={() => {
                                                        setActiveInput(field);
                                                        setShowKeyboard(true);
                                                    }}
                                                    onChange={(e) => handleInputChange(e.target.value)}
                                                />
                                            </div>
                                        ) : (
                                            <input
                                                className="free-trial-input"
                                                style={{...inputStyles.input, fontSize: '3vw'}}
                                                value={inputValues[field]}
                                                onFocus={() => {
                                                    setActiveInput(field);
                                                    setShowKeyboard(true);
                                                }}
                                                onChange={(e) => handleInputChange(e.target.value)}
                                            />
                                        )}
                                    </div>
                                ))}
                            </div>

                            <div id='error_message'></div>

                            <div className="d-flex justify-content-between" style={{
                                marginTop: '3vw',
                                flexDirection: getFlexDirection(language),
                                direction: getDirection(language)}}
                            >
                                <div
                                    className="d-flex flex-column free-trial-input-container"
                                    style={{
                                        flexDirection: getFlexDirection(language),
                                        marginRight: '2vw',
                                        width: '26.754vw',
                                        ...inputStyles.label,
                                    }}
                                >
                                    <label
                                        style={{
                                            textAlign: getTextAlign(language),
                                            direction: getDirection(language),
                                        }}
                                    >
                                        {t('updateInformationGender')}
                                    </label>
                                    <select
                                        className="free-trial-input"
                                        value={usersData.user.gender || 'M'} // Default to 'M' if gender is undefined
                                        onChange={(e) => {
                                            const updatedGender = e.target.value;
                                            setUsersData((prevData) => ({
                                                ...prevData,
                                                user: {
                                                    ...prevData.user,
                                                    gender: updatedGender,
                                                },
                                            }));
                                        }}
                                    >
                                        <option value="F">{t('updateInformationFemale')}</option>
                                        <option value="M">{t('updateInformationMale')}</option>
                                    </select>
                                </div>

                                <div
                                    className="d-flex flex-column free-trial-input-container"
                                    style={{
                                        flexDirection: getFlexDirection(language),
                                        width: '26.754vw',
                                        ...inputStyles.label,
                                    }}
                                >
                                <label
                                        style={{
                                            textAlign: getTextAlign(language),
                                            direction: getDirection(language),
                                        }}
                                    >
                                        {t('updateInformationBirthday')}
                                    </label>
                                    <DatePicker
                                        onChange={(date) => {
                                            if (date) {
                                                // Format the selected date as "dd/MM/yyyy"
                                                const formattedDate = format(date, 'dd/MM/yyyy');

                                                // Update usersData.user.birthday
                                                setUsersData((prevData) => ({
                                                    ...prevData,
                                                    user: {
                                                        ...prevData.user,
                                                        birthday: formattedDate,
                                                    },
                                                }));
                                            }
                                        }}
                                        locale={language === 'ar' ? 'ar' : 'en'}
                                        dateFormat={language === 'ar' ? 'dd/MM/yyyy' : 'dd.MM.yyyy'}
                                        placeholderText={language === 'ar' ? 'يوم/شهر/سنة' : 'dd.mm.yyyy'}
                                        maxDate={new Date()} // Disallow dates in the future
                                        selected={
                                            usersData.user.birthday
                                                ? // Convert "dd/MM/yyyy" to a Date object
                                                new Date(
                                                    usersData.user.birthday.split('/')[2], // Year
                                                    usersData.user.birthday.split('/')[1] - 1, // Month (0-based index)
                                                    usersData.user.birthday.split('/')[0] // Day
                                                )
                                                : null // Handle empty or invalid birthday
                                        }
                                    />
                                </div>
                            </div>

                            <div className="d-flex flex-column" style={{
                                flexDirection: getFlexDirection(language),
                                marginTop: '2vw',
                                direction: getDirection(language)
                            }}>
                                <p style={{fontSize: '3vw'}}>{t('updateInformationChangeHomeClub')}</p>
                                <div style={{width: '100%', marginTop: '1vw'}}>
                                    <select
                                        onChange={(e) => handleGymSelect(e.target.value)}
                                        style={{
                                            width: '50%',
                                            padding: '1vw',
                                            fontSize: '3vw',
                                            height: '7vw'
                                        }}
                                        value={selectedGym}
                                    >
                                        {usersData.gyms.map((gym, index) => (
                                            <option
                                                key={index}
                                                value={gym.gym_name}
                                                style={{
                                                    fontSize: '15px',
                                                }}
                                            >
                                                {gym.gym_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div style={{
                                flexDirection: getFlexDirection(language),
                                direction: getDirection(language)
                            }}>
                                <p id="isEligibleToChangeCLubErrorMessage" style={{
                                    fontSize:'3vw',
                                    color:'red',
                                    display: 'none'
                                }}>
                                    {t('updateInformationErrorMessage')}
                                </p>
                            </div>

                        </div>
                    </div>
                    <button
                        className="btn btn-primary"
                        style={{fontSize:'4vw', marginTop:'20vw'}}
                        disabled={!isEligibleClubChange}
                        onClick={fetchGymChangeUpdate}
                    >
                        {t('myMembershipUpdateDetailsBtn')}
                    </button>

                    {/* Virtual Keyboard */}
                    {showKeyboard && (
                        <VirtualKeyboard
                            layoutName={layoutName}
                            inputValue={inputValues[activeInput] || ""}
                            setInputValue={handleInputChange}
                            onKeyPress={handleKeyPress}
                            language={language}
                        />
                    )}

                </div>
            ) : (
                <p>Loading membership data...</p>
            )}
        </div>
    );
}
