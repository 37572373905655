import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from 'react';
import MembershipCancellationBeforeCommitment from "../components/MembershipCancellationBeforeCommitment";
import MembershipCancellationAfterCommitment from "../components/MembershipCancellationAfterCommitment";


export default function MembershipCancellationPage() {
    const [step, setStep] = useState(1);

    const nextStep = () => setStep((prevStep) => prevStep + 1);

    return (
        <div>
            {step === 1 && (
                <>
                    <MembershipCancellationBeforeCommitment />
                    <button onClick={nextStep}>Next</button>
                </>
            )}
            {step === 2 && <MembershipCancellationAfterCommitment />}
        </div>
    );
}
