import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import HomePage from "./pages/HomePage";
import {Layout} from "./components/Layout";
import LoginPage from "./pages/LogInPage";
import FreeTrialPage from "./pages/FreeTrialPage";
import FreeTrialPassPage from "./pages/FreeTrialPass";
import FreeTrialFailPage from "./pages/FreeTrialFail";
import MemberPage from "./pages/Member";
import JoinNowPage from "./pages/JoinNowPage";
import MyMembershipPage from "./pages/MyMembershipPage";
import {GeneralProvider} from "./components/GeneralProvider";
import LoggedInHomePage from "./pages/LoggedInHomePage";
import ChangeHomeClubPage from "./pages/ChangeHomeclub";
import FreezeMembershipPage from "./pages/FreezeMembershipPage";
import FreezeMembershipPaymentPending from "./pages/FreezeMembershipPaymentPending";
import MembershipCancellationPage from "./pages/MembershipCancellationPage";
import UpgradeAddOnsPage from "./pages/UpgradeAddOns";
import UpdateInformationPage from "./pages/UpdateInformation";
import ChangeHomeClubSuccess from "./pages/ChangeHomeClubSuccess";
import UpdateInformationSuccess from "./pages/UpdateInformationSuccess";
import MembershipCancellationSuccessful from "./pages/MembershipCancellationSuccessful";
import MembershipPaymentPage from "./pages/MembershipPaymentPage";

export default function App() {
    return (
        <GeneralProvider>
            <Router>
                <Routes>
                    <Route element={<Layout/>}>
                        <Route path="/login/" element={<LoginPage />} />
                        <Route path="/free-trial/" element={<FreeTrialPage />} />
                        <Route path="/free-trial-pass/" element={<FreeTrialPassPage />} />
                        <Route path="/free-trial-fail/" element={<FreeTrialFailPage />} />
                        <Route path="/member/" element={<MemberPage />} />
                        <Route path="/join-now/" element={<JoinNowPage />} />
                        <Route path="/my-membership/" element={<MyMembershipPage />} />
                        <Route path="/logged-in-home-page/" element={<LoggedInHomePage />} />
                        <Route path="/change-home-club/" element={<ChangeHomeClubPage />} />
                        <Route path="/freeze-membership/" element={<FreezeMembershipPage />} />
                        <Route path="/freeze-membership-pending-payment/" element={<FreezeMembershipPaymentPending />} />
                        <Route path="/membership-cancellation/" element={<MembershipCancellationPage />} />
                        <Route path="/upgrade/" element={<UpgradeAddOnsPage />} />
                        <Route path="/update-details/" element={<UpdateInformationPage />} />
                        <Route path="/change-home-club-success/" element={<ChangeHomeClubSuccess />} />
                        <Route path="/update-details-success/" element={<UpdateInformationSuccess />} />
                        <Route path="/membership-cancellation-success/" element={<MembershipCancellationSuccessful />} />
                        {/*<Route path="/membership-payment/" element={<MembershipPaymentPage />} />*/}
                    </Route>
                    <Route path="/home-page/" element={<HomePage />} />
                </Routes>
            </Router>
        </GeneralProvider>
    );
}