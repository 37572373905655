import React, { useRef, useEffect } from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

const KeyboardComponent = ({
  layoutName = "default",
  inputName,
  onChangeAll,
  onKeyPress,
  inputValue,
  setInputValue, // New prop to update input value
  language = "en",
}) => {
  const keyboard = useRef();

  useEffect(() => {
    if (keyboard.current && inputValue !== undefined) {
      keyboard.current.setInput(inputValue);
    }
  }, [inputValue]);

  const layouts = {
    en: {
      default: [
        "1 2 3 4 5 6 7 8 9 0",
        "q w e r t y u i o p",
        "a s d f g h j k l",
        "z x c v b n m - @ .",
        "{shift} {space} {bksp}",
      ],
      shift: [
        "1 2 3 4 5 6 7 8 9 0",
        "Q W E R T Y U I O P",
        "A S D F G H J K L",
        "Z X C V B N M - @ .",
        "{shift} {space} {bksp}",
      ],
    },
    ar: {
      default: [
        "1 2 3 4 5 6 7 8 9 0",
        "ض ص ث ق ف غ ع ه خ ح ج",
        "ش س ي ب ل ا ت ن م ك",
        "ئ ء ؤ ر ى ة و ز @",
        "{space} {bksp} .",
      ],
    },
  };

  const handleKeyPress = (button) => {
    if (button === "{bksp}") {
      // Remove the last character from input value
      setInputValue(inputValue.slice(0, -1));
    } else if (button === "{space}") {
      // Add a space to the input value
      setInputValue(inputValue + " ");
    } else if (button === "{shift}") {
      // Toggle the shift layout
      const newLayout = layoutName === "default" ? "shift" : "default";
      keyboard.current.setOptions({ layoutName: newLayout });
    } else {
      // Add the pressed character to the input value
      setInputValue(inputValue + button);
    }

    if (onKeyPress) {
      onKeyPress(button);
    }
  };

  return (
    <Keyboard
      keyboardRef={(r) => (keyboard.current = r)}
      inputName={inputName}
      layoutName={layoutName}
      layout={layouts[language]}
      onChangeAll={onChangeAll}
      onKeyPress={handleKeyPress}
    />
  );
};

export default KeyboardComponent;
