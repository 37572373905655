import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from 'react-i18next';
import Sidebar from "../components/Sidebar";
import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { enGB, arSA } from 'date-fns/locale';
import '../assets/styles/customReactDatePickerStyle.css';
import { format } from 'date-fns';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';


export default function FreezeMembershipPage() {
    const language = localStorage.getItem('language') || 'en';
    const { t } = useTranslation()
    const [freezeMembershipData, setFreezeMembershipData] = useState()
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const navigate = useNavigate();

    registerLocale('en', enGB);
    registerLocale('ar', arSA);

    const getTextAlign = (lang) => lang === 'ar' ? 'right' : 'left';
    const getDirection = (lang) => lang === 'ar' ? 'rtl' : 'ltr';
    const getFlexDirection = (lang) => lang === 'ar' ? 'row-reverse' : 'row';

    const renderLabel = (labelText) => {
        return language === 'ar' ? `: ${labelText}` : `${labelText} :`;
    };

    const fetchFreezeMembershipData = async () => {
        try {
            const token = localStorage.getItem('access_token');
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/freeze-membership-data/`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              }
            });

            if (!response.ok) {
                throw new Error('An unexpected error occurred. Please try again.');
            }

            const data = await response.json();
            setFreezeMembershipData(data);
          } catch (error) {
            console.error('Error:', error);
            throw new Error('An unexpected error occurred. Please try again.');
          }
    };

    const fetchPendingFreezeMembership = async () => {
        const token = localStorage.getItem('access_token');

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/pending-free-membership/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ membership_freeze_start_date: startDate, membership_freeze_end_date: endDate })
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            navigate('/freeze-membership-pending-payment/');
        } catch (error) {
            console.error('Error changing gym:', error);
            const notify = () => toast("Wow so easy !");
        }
    };

    useEffect(() => {
        fetchFreezeMembershipData();
      }, []);

    // Helper functions to get minimum and maximum dates for the end date
    const getMinEndDate = () => {
        if (!startDate) return null;
        const minEndDate = new Date(startDate);
        minEndDate.setMonth(minEndDate.getMonth() + 1); // 1 month after start date
        return minEndDate;
    };

    const getMaxEndDate = () => {
        if (!startDate) return null;
        const maxEndDate = new Date(startDate);
        maxEndDate.setMonth(maxEndDate.getMonth() + 3); // 3 months after start date
        return maxEndDate;
    };

    return (
        <div>
            {freezeMembershipData ? (
                <div className="d-flex flex-column justify-content-center align-items-center w-100">
                    <div className="d-flex flex-row mt-5">

                        <div style={{marginLeft: '2vw'}}>
                            <Sidebar/>
                        </div>

                        <div style={{marginLeft: '5vw', marginRight: '5vw'}}>
                            <p style={{
                                fontSize: '6vw',
                                fontWeight: 'bold',
                                textAlign: getTextAlign(language),
                            }}>
                                {t('myMembershipFreezeMembershipBtn')}
                            </p>

                            <div className="d-flex"
                                 style={{flexDirection: getFlexDirection(language), marginTop: '2vw'}}>
                                <p style={{fontSize: '4vw'}}>{renderLabel(t('myMembershipMembershipType'))}</p>
                                <p style={{
                                    marginLeft: language === 'en' ? '1vw' : '0',
                                    marginRight: language === 'ar' ? '1vw' : '0',
                                    fontSize: '4vw'
                                }}>{freezeMembershipData.membership_type.name}</p>
                            </div>

                            <div
                                className="d-flex"
                                style={{flexDirection: getFlexDirection(language), marginTop: '2vw'}}
                            >
                                <p style={{fontSize: '4vw'}}>{renderLabel(t('freezeMembershipStartDate'))}</p>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => {
                                        const formattedDate = format(date, 'yyyy-MM-dd'); // Format date as 'YYYY-MM-DD'
                                        setStartDate(formattedDate); // Store the formatted date
                                        setEndDate(null); // Reset end date if start date changes
                                    }}
                                    locale={language === 'ar' ? 'ar' : 'en'}
                                    dateFormat={language === 'ar' ? 'dd/MM/yyyy' : 'dd.MM.yyyy'}
                                    placeholderText={language === 'ar' ? 'يوم/شهر/سنة' : 'dd.mm.yyyy'}
                                    minDate={new Date()} // Start date cannot be before today
                                />
                            </div>

                            {/* End Date Picker */}
                            <div
                                className="d-flex"
                                style={{flexDirection: getFlexDirection(language), marginTop: '2vw'}}
                            >
                                <p style={{fontSize: '4vw'}}>{renderLabel(t('freezeMembershipEndDate'))}</p>
                                <DatePicker
                                    selected={endDate}
                                    onChange={(date) => {
                                        const formattedDate = format(date, 'yyyy-MM-dd'); // Format date as 'YYYY-MM-DD'
                                        setEndDate(formattedDate); // Store the formatted date
                                    }}
                                    locale={language === 'ar' ? 'ar' : 'en'}
                                    dateFormat={language === 'ar' ? 'dd/MM/yyyy' : 'dd.MM.yyyy'}
                                    placeholderText={language === 'ar' ? 'يوم/شهر/سنة' : 'dd.mm.yyyy'}
                                    minDate={getMinEndDate()} // Minimum 1 month after start date
                                    maxDate={getMaxEndDate()} // Maximum 3 months after start date
                                    disabled={!startDate} // Disable end date picker until start date is set
                                />
                            </div>

                            <div className="d-flex"
                                 style={{flexDirection: getFlexDirection(language), marginTop: '2vw'}}>
                                <p style={{fontSize: '4vw'}}>{renderLabel(t('freezeMembershipFee'))}</p>
                                <p style={{
                                    marginLeft: language === 'en' ? '1vw' : '0',
                                    marginRight: language === 'ar' ? '1vw' : '0',
                                    fontSize: '4vw',
                                }}>{freezeMembershipData.fee.price}</p>
                            </div>

                        </div>
                    </div>
                    <button
                        onClick={fetchPendingFreezeMembership}
                        className='btn btn-primary'
                        style={{
                            marginTop: '50vw',
                            fontSize: '5vw',
                        }}
                    >
                        {t('payNowBtn')}
                    </button>
                </div>
            ) : (
                <p>Loading membership data...</p>
            )}
        </div>
    );
}
