import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function Sidebar() {
    const language = localStorage.getItem('language') || 'en';
    const { t } = useTranslation();

    const getTextAlign = (lang) => lang === 'ar' ? 'right' : 'left';
    const getDirection = (lang) => lang === 'ar' ? 'rtl' : 'ltr';

    const dotPosition = language === 'en' ? 'left' : 'right';

    return (
        <div style={{ width: '32vw', backgroundColor: '#D9D9D9' }} className="d-flex flex-column">
            <p style={{
                fontSize: '3.5vw',
                textAlign: getTextAlign(language),
                direction: getDirection(language),
                paddingLeft: dotPosition === 'left' ? '1vw' : '0',
                paddingRight: dotPosition === 'right' ? '1vw' : '0',
                fontWeight: 'bold',
                marginTop: '1vw'
            }}>
                {t('memberPageMyMembershipBtn')}
            </p>
            <Link
                to="/my-membership/"
                style={{
                    fontSize: '3.5vw',
                    textAlign: getTextAlign(language),
                    cursor: 'pointer',
                    color: '#615E82',
                    paddingLeft: dotPosition === 'left' ? '1vw' : '0',
                    paddingRight: dotPosition === 'right' ? '1vw' : '0',
                    marginTop: '2vw'
                }}>
                {language === 'en' ? '•' : ''} {t('memberPageMyMembershipBtn')} {language === 'ar' ? '•' : ''}
            </Link>
            <Link
                to="/update-details/"
                style={{
                    fontSize: '3.5vw',
                    textAlign: getTextAlign(language),
                    cursor: 'pointer',
                    color: '#615E82',
                    paddingLeft: dotPosition === 'left' ? '1vw' : '0',
                    paddingRight: dotPosition === 'right' ? '1vw' : '0',
                    marginTop: '2vw'

                }}>
                {language === 'en' ? '•' : ''} {t('myMembershipUpdateDetailsBtn')} {language === 'ar' ? '•' : ''}
            </Link>
            <Link
                to="/freeze-membership/"
                style={{
                    fontSize: '3.5vw',
                    textAlign: getTextAlign(language),
                    cursor: 'pointer',
                    color: '#615E82',
                    paddingLeft: dotPosition === 'left' ? '1vw' : '0',
                    paddingRight: dotPosition === 'right' ? '1vw' : '0',
                    marginTop: '2vw'
                }}>
                {language === 'en' ? '•' : ''} {t('myMembershipFreezeMembershipBtn')} {language === 'ar' ? '•' : ''}
            </Link>
            <Link
                to="/membership-cancellation/"
                style={{
                    fontSize: '3.5vw',
                    textAlign: getTextAlign(language),
                    cursor: 'pointer',
                    color: '#615E82',
                    paddingLeft: dotPosition === 'left' ? '1vw' : '0',
                    paddingRight: dotPosition === 'right' ? '1vw' : '0',
                    marginTop: '2vw'
                }}>
                {language === 'en' ? '•' : ''} {t('myMembershipMembershipCancelationBtn')} {language === 'ar' ? '•' : ''}
            </Link>
            <Link
                to="/change-home-club/"
                style={{
                    fontSize: '3.5vw',
                    textAlign: getTextAlign(language),
                    cursor: 'pointer',
                    color: '#615E82',
                    paddingLeft: dotPosition === 'left' ? '1vw' : '0',
                    paddingRight: dotPosition === 'right' ? '1vw' : '0',
                    marginTop: '2vw'
                }}>
                {language === 'en' ? '•' : ''} {t('myMembershipChangeHomeClubBtn')} {language === 'ar' ? '•' : ''}
            </Link>
        </div>
    );
}
