import React, { createContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { handleLogout } from '../utils/generalFunctions';

export const GeneralContext = createContext();

export const GeneralProvider = ({ children }) => {
    const [showPopup, setShowPopup] = useState(false);
    const [timer, setTimer] = useState(10); // Countdown starts from 10 seconds
    const [isOnline, setIsOnline] = useState(true); // Internet connectivity status
    const [isServerOnline, setIsServerOnline] = useState(true); // Server connectivity status
    const timerRef = useRef(null); // Reference to the inactivity timer
    const popupTimeout = 1000000; // 5 seconds of inactivity to trigger popup
    const { t } = useTranslation();

    const isHomePage = window.location.pathname === '/home-page/';

    // Function to reset the inactivity timer
    const resetTimer = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current); // Clear the previous timer
        }
        setTimer(10); // Reset countdown to 10 seconds

        // Set a new timer to trigger popup after 5 seconds of inactivity
        timerRef.current = setTimeout(() => {
            setShowPopup(true); // Show the popup after inactivity
        }, popupTimeout);
    };

    // Countdown effect when the popup is shown
    useEffect(() => {
        let countdownInterval;
        if (showPopup) {
            countdownInterval = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer <= 1) {
                        closePopup();
                        handleLogout();
                        return 0;
                    }
                    return prevTimer - 1; // Decrease timer by 1 each second
                });
            }, 1000);
        }

        // Clear the countdown interval when popup is closed
        return () => clearInterval(countdownInterval);
    }, [showPopup]);

    // Function to check internet connectivity
    const checkInternetConnectivity = async () => {
        try {
            await fetch('https://www.google.com', { method: 'HEAD', mode: 'no-cors' });
            setIsOnline(true);
        } catch (error) {
            setIsOnline(false);
        }
    };

    // Function to check server connectivity
    const checkServerConnectivity = async () => {
        try {
            await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/connection-check/`, { method: 'GET' });
            setIsServerOnline(true);
        } catch (error) {
            setIsServerOnline(false);
        }
    };

    // Combined check for both internet and server connectivity
    // useEffect(() => {
    //     const checkConnectivity = async () => {
    //         await checkInternetConnectivity();
    //         if (isOnline) {
    //             await checkServerConnectivity();
    //         }
    //     };
    //
    //     const interval = setInterval(checkConnectivity, 5000); // Check every 5 seconds
    //     checkConnectivity(); // Initial check on component mount
    //
    //     return () => clearInterval(interval); // Cleanup on unmount
    // }, [isOnline]);

    // Set up touch event listeners to reset the timer on any touch interaction
    useEffect(() => {
        if (isHomePage) return;
        const touchEvents = ['touchstart', 'touchmove', 'touchend'];

        touchEvents.forEach((event) => {
            window.addEventListener(event, resetTimer);
        });

        // Initially start the timer when the component is mounted
        resetTimer();

        // Clean up the event listeners and timer on component unmount
        return () => {
            touchEvents.forEach((event) => {
                window.removeEventListener(event, resetTimer);
            });
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, []);

    // Logout user if connectivity is lost
    useEffect(() => {
        if (!isOnline || !isServerOnline) {
            if (isHomePage) return;
            handleLogout();
        }
    }, [isOnline, isServerOnline]);

    // Function to close the popup and reset the timer
    const closePopup = () => {
        setShowPopup(false);
        setTimer(10); // Reset timer to 10 seconds for next time
    };

    return (
        <GeneralContext.Provider value={{ showPopup, closePopup, isOnline }}>
            {children}

            {/* Inactivity Popup */}
            {showPopup && (
                <div className="popup-overlay" onClick={closePopup}>
                    <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                        <h2>{t('inactivePopUpInactiveText')}</h2>
                        <p>{t('inactivePopUpTimerText', {
                            count: timer,
                        })}</p>
                        <button onClick={closePopup} className="mt-2">{t('inactivePopUpStayLoggedInBtn')}</button>
                    </div>
                </div>
            )}

            {/* Fullscreen Connectivity Popup */}
            {!isOnline && (
                <div className="internet-popup-overlay">
                    <div className="internet-popup-content">
                        <h2>Sorry, this device is currently unavailable due to the lack of an Internet connection.</h2>
                        <h2>عذرًا، هذا الجهاز غير متاح حاليًا بسبب عدم وجود اتصال بالإنترنت.</h2>
                    </div>
                </div>
            )}

            {!isServerOnline && isOnline && (
                <div className="internet-popup-overlay">
                    <div className="internet-popup-content">
                        <h2>Sorry, this device is unavailable. Unable to connect to the server.</h2>
                        <h2>عذرًا، هذا الجهاز غير متاح. لا يمكن الاتصال بالخادم.</h2>
                    </div>
                </div>
            )}
        </GeneralContext.Provider>
    );
};
